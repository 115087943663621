import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import LogoRaw from '../images/logo.png'

const LogoPath = `https://hortonlane.com${ LogoRaw }`

const PINTEREST_VERIFICATION = '48c15e2a98756bc788e7e17efef17cb2'
const BING_VERIFICATION = '95BF0E985A978F5BD9FCB4D92A4FB299'
const LOGO_LD = `
    {
      "@context": "http://schema.org",
      "@type": "Horton Lane",
      "url": "https://hortonlane.com",
      "logo": "${ LogoPath }"
    }
`

const SOCIAL_LD = `
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Horton Lane",
    "url": "https://hortonlane.com",
    "sameAs": [
      "https://www.instagram.com/hortonlane_",
      "https://www.pinterest.com/HortonLane_",
      "https://www.tiktok.com/@hortonlane"
    ]
  }
`

export const Seo = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            siteURL
          }
        }
      }
    `}
    render={ data => {
      const { siteMetadata } = data.site
      const title = `${ siteMetadata.title } | ${ props.title }`
      const url = `${ siteMetadata.siteURL }${ props.location.pathname }`
      const meta = []
      meta.push({
        name: 'title',
        content: title
      })
      if (props.description) {
        meta.push({
          name: 'description',
          content: props.description
        })
      }
      if (props.keywords) {
        meta.push({
          name: 'keywords',
          content: props.keywords
        })
      }

      return (
        <Helmet>
          <html lang="en" />
          <title>{ title }</title>
          { meta.map(m => (
            <meta key={m.name} name={m.name} content={m.content} />
          ))}
          <script type="application/ld+json">{LOGO_LD}</script>
          <script type="application/ld+json">{SOCIAL_LD}</script>
          {/* Pinterest Verification */}
          <meta name="p:domain_verify" content={PINTEREST_VERIFICATION} />
          {/* Bing Verification */}
          <meta name="msvalidate.01" content={BING_VERIFICATION} />

          <meta property='twitter:title' content={title}/>
          <meta property='twitter:description' content={props.description}/>
          <meta property='og:title' content={title}/>
          <meta property='og:url' content={url}/>
          <meta property='og:description' content={props.description}/>
          <meta property='og:type' content={'article'}/>
          <meta property='og:image' content={LogoPath} />
        </Helmet>
      )
    }}
  />
)
