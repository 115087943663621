import React from 'react'
import { Template } from '../components/mainContainer'
import { Seo } from '../components/seo'

class PageNotFound extends React.Component {
  render () {
    return (
      <Template>
        <Seo title="We can't find that" location={this.props.location} />
        <h6>We couldn't find what you were looking for!</h6>
      </Template>
    )
  }
}

export default PageNotFound
